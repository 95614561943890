<template>
  <BodySimple :loading="firstLoading">
    <template #header>
      <div class="flex items-center">
        <img src="@/assets/images/icons8-money-transfer-96.png" class="h-20 ml-2" />
        <div class="text-xl font-bold">لیست سفارش تبلیغات</div>
      </div>
      <h2 class="text-xl font-bold mt-3">
        <Icon name="Group"></Icon>
        {{ group.name }}
      </h2>
      <div class="mt-3 text-right" dir="ltr">{{ group.gid }}</div>
    </template>
    <template #default>
      <div class="flex flex-wrap p-3 bg-teal-600 text-center text-teal-50">
        <div class="w-6/12 md:w-3/12">موضوع تبلیغ</div>
        <div class="w-6/12 md:w-3/12">ساعت ارسال</div>
        <div class="w-6/12 md:w-2/12">ارسال</div>
        <div class="w-6/12 md:w-2/12">حذف</div>
        <div class="w-6/12 md:w-2/12">مبلغ (تومان)</div>
      </div>
      <div
        v-for="ad in ads"
        :key="ad.id"
        class="flex flex-wrap p-3 odd:bg-blueGray-100 text-center"
      >
        <div class="w-6/12 md:w-3/12">{{ ad.type || "سایر موارد" }}</div>
        <div class="w-6/12 md:w-3/12">{{ ad.send_at }}</div>
        <div class="w-6/12 md:w-2/12">
          {{ { ok: "موفق", fail: "خطا", no: "انتظار" }[ad.sended] }}
        </div>
        <div class="w-6/12 md:w-2/12">
          {{ { ok: "موفق", fail: "خطا", no: "انتظار" }[ad.deleted] }}
        </div>
        <div class="w-6/12 md:w-2/12">{{ $number.format(ad.price) }}</div>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import Icon from "@/components/icon/Icon.vue";

export default {
  components: {
    BodySimple,
    Icon,
  },
  data() {
    return {
      firstLoading: true,
      group: {},
      ads: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let $this = this;
      // $this.firstLoading = true;
      $this.$axios
        .get("/api/group-income/ad/" + $this.$route.params.groupId, {
          params: {
            id: $this.$store.state.user.id,
            // date: $this.date,
          },
        })
        .then(function (response) {
          // console.log(response);
          $this.group = response.data.group;
          $this.ads = response.data.ad;
          // $this.users = response.data.users;
          // $this.date = response.data.date;
        })
        .catch(function (error) {
          // console.log(error);
        })
        .then(function () {
          $this.firstLoading = false;
          $this.sending = false;
        });
    },
  },
};
</script>

<style></style>
